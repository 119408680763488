<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        (a) A method of analysis yields weights for platinum that are low by
        <stemble-latex content="$0.5\,\text{mg}$" />
        . Calculate the percent relative error caused by this uncertainty if the weight of platinum
        in the sample is
        <stemble-latex content="$650\,\text{mg}$" />.
      </p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{a)}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (b) The method in part a is to be used for the analysis of ores that assay
        <stemble-latex content="$4.7\%$" />
        platinum. Calculate the minimum sample weight that should be taken if the relative error
        resulting from a
        <stemble-latex content="$0.5\,\text{mg}$" />
        loss is not to exceed
        <stemble-latex content="$\pm2.5\%$" />
        .
      </p>
      <calculation-input
        v-model="inputs.input2"
        class="mb-5"
        prepend-text="$\text{b)}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUPEI2210A1Q4',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
};
</script>
